/*eslint-disable*/
import React from "react";
import Head from "next/head";
import { getShare } from "src/LogoGenerator";

export default function HeadData(props) {
  React.useEffect(() => {

  });

  const defaultData = {
    title: 'Piracy Plus',
    description: 'Piracy Plus - Cinedapt® is the only patented and proven solution to protect against film piracy.',
    image: '/assets/piracy-plus-share.png',
    slug: ''
  };
  const headerData = (props.headerData ? props.headerData : defaultData);

  const shareImage = getShare();
  const imageURL = 'https://piracyplus.com/assets/images/' + shareImage;
  const seoURL = 'https://piracyplus.com/' + headerData.slug;

  return (
    <Head>

      {/* Primary Meta Tags */}
      <title>{headerData.title}</title>
      <meta name='title' content={headerData.title} />
      <meta name="description" content={headerData.description} />

      {/* Open Graph / Facebook */}
      <meta property='og:type' content='website' />
      <meta property="og:title" content={headerData.title} key="title" />
      <meta property="og:description" content={headerData.description} />
      <meta property="og:site_name" content="Piracy Plus" />
      <meta property="og:url" content={seoURL} />
      <meta property='og:image' content={imageURL} />
      <meta property="og:image:secure_url" content={imageURL} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='628' />

      {/* Twitter */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={imageURL} />
      <meta property='twitter:title' content={headerData.title} />
      <meta property='twitter:description' content={headerData.description} />
      <meta property='twitter:image' content={imageURL} />
      <meta name="twitter:title" content={headerData.title} />
      <meta name="twitter:description" content={headerData.description} />
      <meta name="twitter:image" content={imageURL} />

      <link rel="canonical" href={seoURL} />

    </Head>
  );
}
